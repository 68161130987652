<template>
    <div>
        <!-- Nav -->
        <i id="scroll-indicator"></i>
        <i id="top"></i>
        <nav class="navbar navbar-expand-xl" id="mainNav">
            <router-link to="/">
                <img v-if="this.$i18n.locale === 'nl'" src="img/logo-ziekenhuizen.svg" class="logo">
            </router-link>
            <button class="navbar-toggler navbar-toggler-right bg-primary text-white collapsed" type="button" data-toggle="collapse" data-target="#navbarResponsive"
                    aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <span> </span>
                <span> </span>
                <span> </span>
            </button>
            <div class="collapse navbar-collapse flex-row-reverse" id="navbarResponsive">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link :to="$t('over-de-tilthermometer-pagina')">{{ $t('over-de-tilthermometer') }}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="$t('veel-gestelde-vragen-pagina')">{{ $t('vragen') }}</router-link>
                    </li>
                    <li v-if="this.$i18n.locale === 'nl'" class="nav-item external-link"><a href="https://www.betermetarbo.nl" target="_blank">Arbocatalogus Ziekenhuizen <i
                        class="fas fa-external-link-alt"></i></a></li>
                    <li v-if="this.$i18n.locale === 'nl'" class="nav-item external-link"><a href="https://www.goedgebruik.nl/filmpjes" target="_blank">Goedgebruik <i
                        class="fas fa-external-link-alt"></i></a></li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'Navigation',
}
</script>
