<template>
    <div id="tilthermometer-ziekenhuizen">
        <Navigation/>
        <Header/>
        <transition name="fade" mode="out-in">
            <keep-alive>
                <router-view name="main"></router-view>
            </keep-alive>
        </transition>
        <Footer/>
        <cookie-law :button-text="$t('akkoord')">
            <div slot="message">
                {{ $t('deze-website-gebruikt-cookies') }}
                <router-link :to="$t('privacy-pagina')">{{ $t('meer-informatie') }}</router-link>
            </div>
        </cookie-law>
    </div>
</template>

<script>
import Navigation from './components/includes/Navigation.vue';
import Header from './components/includes/Header.vue';
import Footer from './components/includes/Footer.vue';
import CookieLaw from 'vue-cookie-law';

export default {
    name:       'tilthermometer-ziekenhuizen',
    components: {
        Navigation,
        Header,
        Footer,
        CookieLaw
    }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .3s
}

.fade-enter, .fade-leave-to {
    opacity: 0
}
</style>
