import Vue from 'vue';
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

//let hostname = window.location.hostname;

/*let loadLocale = /\.com$/.test(hostname) ? 'en' : 'nl';
const lang     = findGetParameter('lang');
loadLocale     = lang ?? loadLocale;*/
let loadLocale = 'nl';

const i18n = new VueI18n({
    locale:   loadLocale,
    messages: {
        nl: require('./nl.json')
    }
});

/*function findGetParameter(parameterName) {
    let result = null,
        tmp    = [];
    location.search
        .substr(1)
        .split("&")
        .forEach(function(item) {
            tmp = item.split("=");
            if(tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}*/

export default i18n;